
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
    mixins: [apiEndpoint],
    name: 'trainer-list',
    components: {
        Form,
        Datatable,
        SearchBar,
        Swal,
    },
    data() {
        return {
            is_seip_user: false,
            is_entity_user: false,
            is_institute_user: false,
            api_url: '',
            AssetSection: false,
            load: false,
            loading: false,
            tranche: [] as any,
            tranches: [] as any,
            association: [] as any,
            btnCheck: false,
            instituteCheck: false,
            instituteList: [] as any,
            batch: {
                entity_id: '',
                tranche_id: '',
                institute_id: '',
                course_id: '',
            },
            address_details: [
                {
                    name: '',
                    entry_date: '',
                    supplier: '',
                    file_no: '',
                    rate_in: '',
                    balance: '',
                    value_acquisition: '',
                    usefull_of_life: '',
                    identification: '',
                    package:'',
                    location: '',
                    opening_balance: '',
                    opening_wdv: '',
                    charged_period: '',
                    wdv_end: '',
                    remarks: '',
                },
            ] as any,
            new_address: {
                name: '',
                entry_date: '',
                supplier: '',
                file_no: '',
                rate_in: '',
                balance: '',
                value_acquisition: '',
                usefull_of_life: '',
                identification: '',
                package:'',
                location: '',
                opening_balance: '',
                opening_wdv: '',
                charged_period: '',
                wdv_end: '',
                remarks: '',
            },
            formData: {
                name: '',
                address: '',
                mobile: ''
            },
            tableHeader: [
                {
                    name: 'Actions',
                    key: 'actions',
                    sortable: false,
                    width: '200px',
                },
                {
                    name: 'Unique Course',
                    key: 'activity_report',
                    sortable: true,
                },
                {
                    name: 'Code',
                    key: 'code',
                    sortable: true,
                },
                {
                    name: 'Name of industry Association / Govt. Org',
                    key: 'association',
                    sortable: true,
                    width: '300px',
                },
                {
                    name: 'Course Found',
                    key: 'course',
                    sortable: true,
                },
            ],
            tranche_id: '' as any,
            entity_id: '' as any,
            course_type: '' as any,
            suppliers: [] as any,
            type: '' as any,
            start_date: '' as any,
            end_date: '' as any,
            showActivityReport: false,
            componentKey: 0,
            actionActive: false,
            activityReport: [] as any,
            isModalVisible: false,
            selectAll: {
                id: 'all',
                label: 'All',
            } as any,
            TotalTrainee: 0 as any,
            Totalenrollment: 0 as any,
            Totalfemale: 0 as any,
            Totalcertification: 0 as any,
            TotalcertificationFemale: 0 as any,
            Totalcertification_percentage: 0 as any,
            total_job_placement: 0 as any,
            total_female_job_placement: 0 as any,
            Totaljob_placement_percentage: 0 as any,
        };
    },
    async created() {
        if (VueCookieNext.getCookie("_seip_entity_type") == 1004 || VueCookieNext.getCookie("_seip_entity_type") == 1000) {
            this.is_seip_user = true;
        }
        if (VueCookieNext.getCookie("_seip_entity_type") != 1004 && VueCookieNext.getCookie("_seip_entity_type") != 1011) {
            this.is_entity_user = true;
            this.is_institute_user = true;
        }
        if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
            this.is_institute_user = true;
        }
        this.api_url = this.VUE_APP_API_URL;
        await this.getTranches();
        await this.getAssociation();
    },
    methods: {

        calculateWDV(data) {
            data.balance = data.opening_balance + data.charged_period;
            data.wdv_end = data.value_acquisition - data.balance;
        },
        openModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        async saveSupplier() {
            let formData = new FormData();
            let entity_id = '';
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else{
                institute_info_id = this.batch.institute_id ;
            }
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else{
                 entity_id = this.batch.entity_id;
            }
            formData.set('organization_type', this.type);
            formData.set('entity_id', entity_id);
            formData.set('institute_id', institute_info_id);
            formData.set('name', this.formData.name);
            formData.set('address', this.formData.address);
            formData.set('mobile', this.formData.mobile);
            let user_id = VueCookieNext.getCookie('_seip_employee_id');
            formData.set('user_id', user_id);
            await ApiService.post('supplier/save', formData)
                .then((response) => {
                    console.log(response);
                    this.getSupplier();
                    this.loading = false;
                    if (response.status == 200 && response?.data?.status == 'success') {
                        Swal.fire({
                            text: response.data.data,
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.closeModal();
                    } else {
                        let err = '';
                        for (const field of Object.keys(response.data.errors)) {
                            err += response.data.errors[field][0] + '<br>';
                        }
                        Swal.fire({
                            title: 'Error!',
                            html: err,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })

        },
        async getTranches() {
            await ApiService.get("configurations/tranche/list")
                .then((response) => {
                    this.tranches = response.data.data;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        async formSubmit() {
            let formData = new FormData();
            let user_id = VueCookieNext.getCookie('_seip_employee_id');
            formData.set(
                'address_details',
                JSON.stringify(this.address_details)
            );

            formData.set('entity_id', this.batch.entity_id);
            formData.set('institute_id', this.batch.institute_id);
            formData.set('user_id', user_id);
            formData.set('type', this.type);
            formData.set('tranche', this.tranche_id);
            let route = 'asset_register/save';
            this.loading = true;
            await ApiService.post(route, formData)
                .then((response) => {
                    console.log(response);
                    this.loading = false;
                    if (response.status == 200) {
                        Swal.fire({
                            text: 'Saved Succesfully',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then(() => {
                            window.location.reload();
                        });
                    } else {
                        let err = '';
                        for (const field of Object.keys(response.data.errors)) {
                            err += response.data.errors[field][0] + '<br>';
                        }
                        Swal.fire({
                            title: 'Error!',
                            html: err,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })
        },
        async getAssociation() {
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get('entity/list?entity_id=' + entity_id)
                .then((response) => {
                    this.association = response.data.data;
                    console.log(response);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getSupplier() {
            let entity_id = '';
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else{
                institute_info_id = this.batch.institute_id ;
            }
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else{
                 entity_id = this.batch.entity_id;
            }
            await ApiService.get('supplier/list?entity_id=' + entity_id +
                '&institute_id=' +
                institute_info_id +
                '&type=' +
                this.type
            )
                .then((response) => {
                    this.suppliers = response.data.data;
                    console.log(response);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async courseActivityReport() {
            this.AssetSection = true;
        },
        async btnCheckStatus() {
            this.load = true;
            if (this.type) {
                if (this.type !== 'SEIP') {
                    this.btnCheck = true;
                }
                else {
                    this.btnCheck = false;
                    this.instituteCheck = false;
                    await this.getSupplier();
                }
                if (this.type == 'Training Institute') {
                    this.instituteCheck = true;
                }
                else {
                    this.instituteCheck = false;
                }

                this.load = false;
            } else {
                Swal.fire({
                    title: 'Organization Type Need to be Select!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete!',
                });
            }
            // else popup
        },
        view(data) {
            // console.log(data);
            this.emitter.emit('course_unique_details', data);
        },

        async getTranche() {
            await ApiService.get('configurations/tranche/list')
                .then((response) => {
                    console.log(response.data.data);
                    this.tranche = response.data.data;
                    this.tranche.unshift(this.selectAll);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        async getTrainingInstitute() {
            await this.getSupplier();
            this.loading = true;
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            await ApiService.get(
                this.VUE_APP_INSTITUTE_LIST_API +
                '?entity_id=' +
                this.batch.entity_id +
                '&institute_info_id=' +
                institute_info_id
            )
                .then((response) => {
                    this.instituteList = response.data.data;
                    this.loading = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                    this.loading = false;
                });
        },
        addProfession() {
            this.address_details.push(JSON.parse(JSON.stringify(this.new_address)));
            console.log(this.address_details);
        },
        removeProfession() {
            this.address_details.length--;
        },

        // async actioncheck() {
        //   let menu = JSON.parse(localStorage.getItem("menu") || "{}");
        //   if (menu) {
        //     for (let i = 0; i < menu.length; i++) {
        //       //console.log(menu[i].action);
        //       if (menu[i].heading == "Trainer") {
        //         //console.log(menu[i].action);
        //         let actions = menu[i].action;
        //         //console.log(actions);
        //         for (let j = 0; j < actions.length; j++) {
        //           if (actions[j].action_name === "Edit") {
        //             // console.log(actions[j].action_name);
        //             this.actionEdit = true;
        //           }
        //           if (actions[j].action_name === "Delete") {
        //             // console.log(actions[j].action_name);
        //             this.actionDelete = true;
        //           }
        //           if (actions[j].action_name === "Approve") {
        //             // console.log(actions[j].action_name);
        //             this.actionActive = true;
        //           }
        //         }
        //       }
        //     }
        //   }
        // },
    },
    setup() {
        const AssociationSchema = [];
        return {
            AssociationSchema,
        };
    },
});
